import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useSWR from "swr";
import { ORG_URL, USER_URL } from "../api";
import { BunsenLogo } from "../components/BunsenLogo";
import { Centered } from "../components/Centered";
import { STORE_AUTO_LOGIN_URL } from "../config";

function InviteWidget({ inviteId }) {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const currentUrl = new URL(window.location.href);
  const inviteUrl = `${currentUrl.origin}/invite/${inviteId}`;

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Add Members
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Share the link below with anyone to let them join your organization on Bunsen.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{inviteUrl}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              await navigator.clipboard.writeText(inviteUrl);
              setCopied(true);
            }}
            variant="outlined"
          >
            {copied ? "Copied!" : "Copy to Clipboard"}
          </Button>
          <Button onClick={handleClose} autoFocus variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function MemberCard({ member }) {
  return (
    <Paper sx={{ p: 2 }}>
      <Grid container columns={{ xs: 6, md: 12 }}>
        <Grid item xs={4}>
          <Typography variant="body1">
            {member.givenName} {member.familyName}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {member.role}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">{member.email}</Typography>
        </Grid>
        {/* 
        <Grid item xs={2}>
          <Button size="small" variant="outlined" fullWidth>
            Edit
          </Button>
        </Grid> 
        */}
      </Grid>
    </Paper>
  );
}

function OrgView({ orgId }) {
  const { data } = useSWR(`${ORG_URL}/${orgId}`);
  const { data: inviteData } = useSWR(`${ORG_URL}/${orgId}/invite`);
  if (!data) return <Centered>Loading...</Centered>;
  return (
    <Box w="100%" p={0}>
      <Stack spacing={2}>
        <Typography variant="h1">{data.org.name}</Typography>
        <Box display="flex" justifyContent={"space-between"}>
          <Typography variant="h2">Members</Typography>
          {inviteData && <InviteWidget inviteId={inviteData.uuid} />}
        </Box>
        <Stack spacing={1}>
          {data.members.map((m, i) => (
            <MemberCard key={i} member={m} />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}

export function Account() {
  const { data } = useSWR(USER_URL);
  // get originUrl from session storage
  // if it's not there, use STORE_AUTO_LOGIN_URL
  const originUrl = sessionStorage.getItem("originUrl") || STORE_AUTO_LOGIN_URL;
  if (!data) return <Centered>Loading...</Centered>;
  if (!data.orgs || !data.orgs.length) return <Centered>No orgs found for user.</Centered>;

  return (
    <>
      <AppBar position="static" color="transparent" sx={{ mb: 4 }}>
        <Container>
          <Toolbar disableGutters>
            <BunsenLogo width="150px" />
            <Box flexGrow={1} />
            <a href={originUrl}>
              <Button variant="outlined" color="secondary">
                Back
              </Button>
            </a>
          </Toolbar>
        </Container>
      </AppBar>

      <Container>
        <OrgView orgId={data.orgs[0].id} />
      </Container>
    </>
  );
}
