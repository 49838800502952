import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { LOGOUT_URL, useApi } from "../api";
import { Centered } from "../components/Centered";
import { STORE_BASE_URL } from "../config";

export function Logout() {
  const { logout } = useAuth0();
  const { fetch } = useApi();

  useEffect(() => {
    const logoutFunc = async () => {
      const response = await fetch(LOGOUT_URL);
      console.log(response);
      logout({ returnTo: STORE_BASE_URL });
    };
    logoutFunc();
  }, [fetch, logout]);

  return <Centered>Loading...</Centered>;
}
