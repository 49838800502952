import { Box, Button, Typography } from "@mui/material";
import { Centered } from "../components/Centered";
import { STORE_BASE_URL } from "../config";

export function PageNotFound() {
  return (
    <Centered>
      <Box textAlign={"center"}>
        <Typography variant="body1">
          Are you sure you're in the right place? We can't find this page.
        </Typography>
        <a href={STORE_BASE_URL}>
          <Button>Return home</Button>
        </a>
      </Box>
    </Centered>
  );
}
