import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SWRConfig } from "swr";
import { useApi } from "./api";
import { Centered } from "./components/Centered";
import { Account } from "./pages/account";
import { AcceptInvite } from "./pages/invite";
import { Login } from "./pages/login";
import { Logout } from "./pages/logout";
import { PageNotFound } from "./pages/notFound";
import { Signup } from "./pages/signup";
import * as Sentry from "@sentry/react";

export function App() {
  let navigate = useNavigate();
  const { fetch } = useApi();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  // set origin of user in session storage if it was provided in the query string
  const url = new URL(window.location.href);
  const originUrl = url.searchParams.get("originUrl");
  if (originUrl) sessionStorage.setItem("originUrl", originUrl);

  // execute login flow if not authenticated
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      sessionStorage.setItem("redirectUrl", window.location.href);
      // check if login or signup flow is requested
      // if path starts with /login, login flow is requested
      const isLogin = window.location.pathname.startsWith("/login");
      const params = isLogin ? {} : { screen_hint: "signup" };
      loginWithRedirect(params);
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  // redirect to url where user started if login flow completed
  useEffect(() => {
    const redirectUrl = sessionStorage.getItem("redirectUrl");
    if (!isLoading && isAuthenticated && redirectUrl) {
      const url = new URL(redirectUrl);
      sessionStorage.removeItem("redirectUrl");
      navigate(url.pathname + url.search);
    }
  }, [isAuthenticated, isLoading, navigate]);

  if (isLoading) return <Centered>Loading...</Centered>;
  if (!isAuthenticated) {
    return <Centered>Redirecting...</Centered>;
  }

  return (
    <SWRConfig
      value={{
        fetcher: (resource) => fetch(resource).then((res) => res.json()),
        onError: (error, key) => {
          if (true) {
            console.error("SWR error", error, key);
            Sentry.captureException(error);
          }
        },
      }}
    >
      <Routes>
        <Route path="/" element={<Signup />} />
        <Route path="/signup/:orgId" element={<Signup />} />
        <Route path="account" element={<Account />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="invite/:inviteId" element={<AcceptInvite />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </SWRConfig>
  );
}
