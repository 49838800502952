import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";

export const USER_URL = "/api/user";
export const ORG_URL = "/api/org";
export const INVITE_URL = "/api/invite";
export const LOGOUT_URL = "/api/logout";

async function fetchWithAuth(resource, options, getTokenFunc) {
  const allOptions = { ...options };
  const token = await getTokenFunc();
  allOptions.headers = {
    "Content-Type": "application/json",
    ...options.headers,
    Authorization: `Bearer ${token}`,
  };
  return await fetch(resource, allOptions);
}

export function useApi() {
  const { getAccessTokenSilently } = useAuth0();
  const fetch = useMemo(() => {
    return (resources, options) => {
      return fetchWithAuth(resources, options || {}, getAccessTokenSilently);
    };
  }, [getAccessTokenSilently]);
  return { fetch };
}
