import { Button, Grid, LinearProgress } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import { useState } from "react";
import * as yup from "yup";

const validationSchema = yup.object({
  familyName: yup.string().required("Family Name is Required."),
  givenName: yup.string().required("Given Name is Required."),
  org: yup.string().required("Organization is required."),
});

export function UserDetailsForm({ onSubmit, initialValues, orgEditable }) {
  const [whichButton, setWhichButton] = useState();
  return (
    <Formik
      initialValues={{
        givenName: "",
        familyName: "",
        org: "",
        ...initialValues,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        values.button = whichButton;
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container columns={12} spacing={2}>
            <Grid item xs={6}>
              <Field
                component={TextField}
                name="givenName"
                type="text"
                label="Given Name"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                name="familyName"
                type="text"
                label="Family Name"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="org"
                type="text"
                label="School / Organization"
                fullWidth
                disabled={!orgEditable}
              />
            </Grid>
            <Grid item xs={12}>
              {isSubmitting && <LinearProgress />}
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={(...args) => {
                  setWhichButton("shop");
                  submitForm(...args);
                }}
                fullWidth
              >
                Go
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="secondary"
                disabled={isSubmitting}
                onClick={(...args) => {
                  setWhichButton("invite");
                  submitForm(...args);
                }}
                fullWidth
              >
                Invite Colleagues
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
