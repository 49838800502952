import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { INVITE_URL, useApi } from "../api";
import { Centered } from "../components/Centered";

export function AcceptInvite() {
  const { inviteId } = useParams();
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();
  const { fetch } = useApi();

  useEffect(() => {
    const acceptInvite = async () => {
      const response = await fetch(`${INVITE_URL}/accept/${inviteId}`).then((response) =>
        response.json()
      );
      const org = response.org;
      setLoading(false);
      navigate(`/signup/${org.id}`);
    };
    acceptInvite();
  }, [fetch, inviteId, navigate]);

  if (loading) return <Centered>Verifying invite..</Centered>;
  return <Centered>Loading profile...</Centered>;
}
