import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { USER_URL } from "../api";
import { Centered } from "../components/Centered";

const AUTO_LOGIN_URL = "https://store.Bunsen.education/pages/auto-login";

export function Login() {
  const { data: userData } = useSWR(USER_URL);
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirectUrl");

  // if userData is retrieved successfully it means the auth cookie is set and we can redirect.
  useEffect(() => {
    if (userData) {
      console.log("redirecting");
      window.location.href = redirectUrl || AUTO_LOGIN_URL;
    }
  }, [userData, redirectUrl]);

  return <Centered>Loading...</Centered>;
}
