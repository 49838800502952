import { createTheme } from "@mui/material";
import { deepOrange, indigo } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: deepOrange,
    secondary: indigo,
  },
  typography: {
    h1: {
      fontFamily: "Raleway",
      fontWeight: "bold",
      fontSize: "24pt",
    },
    h2: {
      fontFamily: "Raleway",
      fontWeight: "bold",
      fontSize: "18pt",
    },
    h4: {
      fontFamily: "Raleway",
      fontWeight: "bold",
    },
    h5: {
      fontFamily: "Raleway",
      fontWeight: "bold",
    },
    h6: {
      fontFamily: "Raleway",
      fontWeight: "bold",
    },
  },
});
