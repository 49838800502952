import { Box, Paper, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { ORG_URL, useApi, USER_URL } from "../api";
import { BunsenLogo } from "../components/BunsenLogo";
import { Centered } from "../components/Centered";
import { UserDetailsForm } from "../components/UserDetailsForm";
import { STORE_AUTO_LOGIN_URL } from "../config";
import { useEffect, useState } from "react";

function isNewOrg(orgName, orgs) {
  return orgs.filter((org) => org.name === orgName).length === 0;
}

async function waitForOrg(fetch, orgId) {
  const numRetries = 10;
  for (let index = 0; index < numRetries; index++) {
    const userData = await fetch(USER_URL).then((x) => x.json());
    console.log("wait for orgs", "data", userData, "orgId", orgId);
    if (orgId && userData.orgs.filter((x) => x.id === orgId).length > 0) return;
    if (!orgId && userData?.orgs?.length > 0) return;
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
  throw new Error("Timeout waiting for orgs");
}

export function Signup() {
  // user may arrive here either from an invite or from a signup link.

  const { orgId } = useParams();
  const { data: userData, mutate } = useSWR(USER_URL);
  const { fetch } = useApi();
  const [waitingForOrg, setWaitingForOrg] = useState(!!orgId);
  const [error, setError] = useState(null);
  const loading = !userData;
  let navigate = useNavigate();

  // orgId is only present if user is coming from an invite.
  // this means they cannot edit their org name
  const joinExisting = !!orgId;

  useEffect(() => {
    // if user is coming from an invite, wait for org permissions to be granted.
    const fetchOrg = async () => {
      try {
        await waitForOrg(fetch, orgId);
        mutate();
        await new Promise((resolve) => setTimeout(resolve, 2000));
      } catch (e) {
        setError(
          "Timeout waiting for permission to be granted. Please contact support@bunsen.education."
        );
      } finally {
        setWaitingForOrg(false);
      }
    };
    if (waitingForOrg) fetchOrg();
  }, [fetch, mutate, orgId, waitingForOrg]);

  const handleSubmit = async (values) => {
    const { button } = values;
    const fieldsToSubmit = ["email", "familyName", "givenName"];
    const payload = Object.fromEntries(fieldsToSubmit.map((x) => [x, values[x]]));

    // update user values, e.g., name.
    await fetch(USER_URL, {
      method: "POST",
      body: JSON.stringify(payload),
    });

    // if new org, create org.
    if (isNewOrg(values.org, userData.orgs)) {
      await fetch(ORG_URL, {
        method: "POST",
        body: JSON.stringify({ name: values.org }),
      });
      mutate();
    }

    // wait for orgs to be created.
    await waitForOrg(fetch);

    const originUrl = sessionStorage.getItem("originUrl") || STORE_AUTO_LOGIN_URL;
    if (button === "shop") window.location = originUrl;
    if (button === "invite") navigate("/account");
  };

  // it is possible that the user is already logged in and clicked signup again.
  // in that case we want to prefill form with their existing values.
  // if they are coming from an invite, we want to prefill with the org name.
  // it is possible that the org name is not available yet, so we wait for it.

  if (loading) {
    return (
      <Centered>
        <Box textAlign={"center"}>Loading...</Box>
      </Centered>
    );
  }

  if (error) {
    return (
      <Centered>
        <Box textAlign={"center"}>{error}</Box>
      </Centered>
    );
  }

  if (waitingForOrg) {
    return (
      <Centered>
        <Box textAlign={"center"}>Waiting for organization data...</Box>
      </Centered>
    );
  }

  const initialValues = { ...userData };
  if (userData?.orgs?.length > 0 && !joinExisting) {
    initialValues.org = userData.orgs[0].name;
  }

  if (userData?.orgs?.length > 0 && joinExisting) {
    initialValues.org = userData.orgs.find((x) => x.id === orgId)?.name;
  }

  return (
    <Centered>
      <Box textAlign={"center"}>
        <BunsenLogo />
      </Box>
      <Box>
        <Paper sx={{ padding: 2, maxWidth: "30rem" }} elevation={4}>
          <Stack spacing={2}>
            <Typography variant="h2">Tell us about you</Typography>
            <Typography variant="subtitle1">
              We need this information to verify that you are part of a school.
            </Typography>
            {!loading && (
              <UserDetailsForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                orgEditable={!joinExisting}
              />
            )}
          </Stack>
        </Paper>
      </Box>
    </Centered>
  );
}
